import React from "react";

export default function Admin(props) {
    return(
        <React.Fragment>
            <div>
                Admin panel
            </div>
        </React.Fragment>
    )
}