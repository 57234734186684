import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from 'react-redux';
import { LOADING, setUserSession } from "../app/actions";

export default function Login(props) {
    const userSession = useSelector((state) => state.userSession)
    const dispatch = useDispatch();

    useEffect(() => {
        async function getSession() {
            try {
                var session = await Auth.currentSession();
                dispatch(setUserSession({ session }));
            } catch (err) {
                console.log(err);
                handleLogin();
            };
        };
        getSession();
    }, []);

    async function handleLogin() {
        try {
            Auth.federatedSignIn({ customProvider: 'Alcatel-Login' });
        } catch (e) {
            alert(e);
        };
    };
};
